import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Flex, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Section from '../components/section'
import SectionWithBackground from '../components/section-with-background'
import Container from '../components/container'
import SectionTitle from '../components/section-title'
import ButtonLink from '../components/button-link'
import HouseTypeList from '../components/house-type-list'

const HotoveProjektyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { pageType: { eq: "projects-page" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___title] }
        filter: { frontmatter: { pageType: { eq: "project" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              slug
              title
              # size
              # priceFrom
              featuredImages {
                childImageSharp {
                  fluid(quality: 85, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "living-room.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      floorplanImage: file(relativePath: { eq: "floorplan.png" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      readyHousesImage: file(relativePath: { eq: "okamzita-vystavba.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { title, subtitle } = data.markdownRemark.frontmatter
  const { edges: houseTypes } = data.allMarkdownRemark
  const backgroundImage = data.backgroundImage.childImageSharp.fluid
  const floorplanImage = data.floorplanImage.childImageSharp.fluid
  const readyHousesImage = data.readyHousesImage.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={title} />

      <Hero
        backgroundImage={backgroundImage}
        title={title}
        subtitle={subtitle}
      />

      <SectionWithBackground fluid={floorplanImage} variant="light">
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <SectionTitle as="h2">Projekty rodinných domů</SectionTitle>

            <Box
              sx={{
                maxWidth: '780px',
                textAlign: 'center',
              }}
            >
              <Text mb={3}>
                Nabízíme výstavbu rodinného domu <strong>typu A</strong>&nbsp;
                na&nbsp;pozemku <strong>č. 68</strong>, kde již bylo
                vydáno platné stavební povolení.
              </Text>

              <Text>
                A výstavbu rodinného domu <strong>typu B</strong>&nbsp;
                na&nbsp;pozemku <strong>č. 34</strong>, kde již bylo také
                vydáno platné stavební povolení.
              </Text>
            </Box>
          </Flex>
        </Container>
      </SectionWithBackground>

      <Section>
        <Container>
          <SectionTitle as="h2">Dostupné projekty</SectionTitle>

          <HouseTypeList houseTypes={houseTypes} slugPrefix="/hotove-projekty" />
        </Container>
      </Section>

      <SectionWithBackground fluid={readyHousesImage} variant="dark" hasOverlay>
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <SectionTitle as="h2" color="white">
              Výstavba typových domů
            </SectionTitle>

            <Text
              sx={{
                maxWidth: '780px',
                textAlign: 'center',
                mb: 4,
              }}
            >
              Nabízíme výstavbu rodinných domů „na klíč“ včetně zpracování prováděcí
              projektové dokumentace a vyřízení stavebního povolení.
            </Text>

            <ButtonLink variant="white" to="/vystavba-domu">
              Výstavba domů
            </ButtonLink>
          </Flex>
        </Container>
      </SectionWithBackground>
    </Layout>
  )
}

export default HotoveProjektyPage
