import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {
  Box, Flex, Heading, Text,
} from 'rebass/styled-components'

import { Link } from 'gatsby'
import Card from './card'
import ButtonLink from './button-link'
import IconPlusCircle from '../images/inline/plus-circle.svg'

const CardImageWrapper = styled(Flex)`
  max-height: 15rem;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`

const CardBody = styled(Flex)``

const CardButton = styled(ButtonLink)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.beige};
  box-sizing: border-box;
  width: 100%;
  line-height: 2rem;
`

const StyledIconPlusCircle = styled(IconPlusCircle)`
  margin-left: 1rem;
  position: relative;
  top: 3px;
`

const HouseTypeCard = ({
  title, size, photo, link,
}) => {
  return (
    <Card
      sx={{
        p: 0,
        justifyContent: 'stretch',
        alignItems: 'stretch',
      }}
    >
      {photo && (
        <Link to={link}>
          <CardImageWrapper>
            <Img fluid={photo.childImageSharp.fluid} alt={title} style={{ width: '100%', height: '100%' }} />
          </CardImageWrapper>
        </Link>
      )}

      <CardBody sx={{
        p: 3,
        width: '100%',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
      >
        <Heading as="h2" mb={2}>{title}</Heading>
        {size && <Text fontSize={4}>{size} m<sup>2</sup></Text>}
      </CardBody>

      <CardButton to={link} variant="light">
        Více informací
        <StyledIconPlusCircle />
      </CardButton>
    </Card>
  )
}

const HouseTypeList = ({ houseTypes, slugPrefix }) => {
  return (
    <Flex flexDirection={['column', 'row']} flexWrap="wrap" justifyContent="space-between">
      {houseTypes
        && houseTypes.map((type) => {
          const columnWidth = houseTypes.length <= 2 ? '48%' : '32%'
          const photo = type.node.frontmatter.featuredImages[0]

          return (
            <Box key={type.node.frontmatter.title} width={[1, columnWidth]} mb={4}>
              <HouseTypeCard
                key={type.node.id}
                title={type.node.frontmatter.title}
                size={type.node.frontmatter.size}
                photo={photo}
                link={`${slugPrefix}/${type.node.frontmatter.slug}`}
              />
            </Box>
          )
        })}
    </Flex>
  )
}

HouseTypeList.propTypes = {
  houseTypes: PropTypes.array,
  slugPrefix: PropTypes.string,
}

HouseTypeCard.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number,
  photo: PropTypes.object,
  link: PropTypes.string,
}

export default HouseTypeList
